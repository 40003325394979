.prices--3pLNq {
  color: hsla(0, 0%, 15%, 1); }
  @media (min-width: 990px) {
    .prices__text-column--1HuIu {
      text-align: center; } }
  .prices__description--2cDKw {
    color: hsla(0, 0%, 65%, 1); }
  .prices__item--1_rqT {
    display: flex;
    border: 1px solid hsla(0, 0%, 85%, 1);
    border-radius: 6px; }
    @media (max-width: 989px) {
      .prices__item--1_rqT {
        margin-bottom: 10px; }
        .prices__item-last--2Wfa4 {
          margin-bottom: 0; } }
  .prices__item-column--2OqQG {
    display: flex;
    flex: 1 1;
    flex-direction: column; }
  .prices__item-title--1hqBw {
    color: hsla(0, 0%, 65%, 1); }
